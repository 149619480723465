import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { DefaultLayout } from "~components/layout/Default"
import { SEO } from "~components/Seo"
import { SliceZoneRenderer } from "~components/elements/SliceZoneRenderer"

export const IndexPageTemplate = ({ activeDocMeta }) => {
    const data = useStaticQuery(graphql`
        query {
            page: allPrismicPage(
                filter: { data: { identifier: { text: { eq: "home" } } } }
            ) {
                edges {
                    node {
                        uid
                        lang
                        alternate_languages {
                            uid
                            type
                            lang
                            url
                            document {
                                ... on PrismicPage {
                                    id
                                    data {
                                        parent_page {
                                            uid
                                        }
                                    }
                                }
                            }
                        }
                        data {
                            layout
                            meta_description {
                                text
                            }
                            name {
                                text
                            }
                            title {
                                text
                            }
                            body {
                                ... on PrismicPageBodyVideo {
                                    id
                                    primary {
                                        headline {
                                            text
                                        }
                                        layout1
                                        subline {
                                            text
                                        }
                                        text {
                                            html
                                        }
                                        video {
                                            thumbnail_url
                                            embed_url
                                            html
                                            title
                                            provider_name
                                        }
                                    }
                                    slice_type
                                }
                                ... on PrismicPageBodyAccordion {
                                    id
                                    primary {
                                        headline {
                                            text
                                        }
                                        subline {
                                            text
                                        }
                                        text {
                                            html
                                        }
                                    }
                                    items {
                                        headline {
                                            text
                                        }
                                        text {
                                            html
                                        }
                                        is_opened
                                    }
                                    slice_type
                                }
                                ... on PrismicPageBodyTiles {
                                    id
                                    slice_type
                                    items {
                                        headline {
                                            text
                                        }
                                        layout
                                        link_label {
                                            text
                                        }
                                        link {
                                            type
                                            uid
                                            lang
                                            url
                                            document {
                                                ... on PrismicPage {
                                                    data {
                                                        parent_page {
                                                            uid
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        subline {
                                            text
                                        }
                                    }
                                }
                                ... on PrismicPageBodyDataPlugin {
                                    id
                                    primary {
                                        plugin_id {
                                            text
                                        }
                                        headline {
                                            text
                                        }
                                        subline {
                                            text
                                        }
                                        text {
                                            html
                                        }
                                    }
                                    slice_type
                                }
                                ... on PrismicPageBodyProductsGrid {
                                    id
                                    slice_type
                                }
                                ... on PrismicPageBodyImageTeaserSlider {
                                    id
                                    primary {
                                        headline {
                                            text
                                        }
                                    }
                                    items {
                                        layout
                                        text {
                                            html
                                        }
                                        link {
                                            uid
                                            type
                                            lang
                                            url
                                            document {
                                                ... on PrismicPage {
                                                    data {
                                                        parent_page {
                                                            uid
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        image {
                                            url
                                        }
                                        headline {
                                            text
                                        }
                                        button_label {
                                            text
                                        }
                                    }
                                    slice_type
                                }
                                ... on PrismicPageBodyQuoteSlider {
                                    id
                                    items {
                                        text {
                                            text
                                        }
                                        subline {
                                            text
                                        }
                                        image {
                                            url
                                            thumbnails {
                                                mobile {
                                                    url
                                                }
                                            }
                                        }
                                    }
                                    slice_type
                                }
                                ... on PrismicPageBodyHistoryEvents {
                                    id
                                    slice_type
                                    primary {
                                        headline {
                                            text
                                        }
                                        subline {
                                            text
                                        }
                                        text {
                                            text
                                        }
                                    }
                                }
                                ... on PrismicPageBodyTextBanner {
                                    id
                                    slice_type
                                    primary {
                                        headline {
                                            text
                                        }
                                        layout
                                        subline {
                                            text
                                        }
                                    }
                                    items {
                                        icon {
                                            url
                                        }
                                        text {
                                            html
                                        }
                                        headline {
                                            text
                                        }
                                    }
                                }
                                ... on PrismicPageBodyTextBlock {
                                    id
                                    primary {
                                        show_arrow
                                        text {
                                            html
                                        }
                                        layout
                                    }
                                    slice_type
                                }
                                ... on PrismicPageBodyTeaserGrid {
                                    id
                                    slice_type
                                    primary {
                                        headline {
                                            text
                                        }
                                        layout
                                    }
                                    items {
                                        text {
                                            html
                                        }
                                        link {
                                            uid
                                            type
                                            lang
                                            url
                                            document {
                                                ... on PrismicPage {
                                                    data {
                                                        parent_page {
                                                            uid
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        image {
                                            url
                                        }
                                        icon {
                                            url
                                        }
                                        headline {
                                            text
                                        }
                                        subline {
                                            text
                                        }
                                        button_label {
                                            text
                                        }
                                    }
                                }
                                ... on PrismicPageBodyHeavyTypoHeader {
                                    id
                                    primary {
                                        subline {
                                            text
                                        }
                                        layout
                                        image {
                                            url
                                        }
                                        headline {
                                            text
                                        }
                                        text {
                                            html
                                        }
                                    }
                                    slice_type
                                }
                                ... on PrismicPageBodyTextAndMenu {
                                    id
                                    primary {
                                        text {
                                            html
                                        }
                                        headline {
                                            text
                                        }
                                    }
                                    slice_type
                                    items {
                                        link {
                                            uid
                                            lang
                                            type
                                            url
                                            document {
                                                ... on PrismicPage {
                                                    data {
                                                        parent_page {
                                                            uid
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        label {
                                            text
                                        }
                                    }
                                }
                                ... on PrismicPageBodyInteractiveVideo {
                                    id
                                    slice_type
                                    primary {
                                        video {
                                            document {
                                                ... on PrismicInteractiveVideo {
                                                    id
                                                    data {
                                                        video_mobile {
                                                            url
                                                        }
                                                        video_desktop {
                                                            url
                                                        }
                                                        video_mobile_mp4 {
                                                            url
                                                        }
                                                        video_desktop_mp4 {
                                                            url
                                                        }
                                                        titles {
                                                            subline {
                                                                text
                                                            }
                                                            link {
                                                                uid
                                                                type
                                                                lang
                                                                url
                                                                document {
                                                                    ... on PrismicPage {
                                                                        data {
                                                                            parent_page {
                                                                                uid
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                            headline {
                                                                text
                                                            }
                                                            fade_out_at
                                                            fade_in_at
                                                        }
                                                        markers {
                                                            mobile_y_position
                                                            link {
                                                                uid
                                                                type
                                                                lang
                                                                url
                                                                document {
                                                                    ... on PrismicPage {
                                                                        data {
                                                                            parent_page {
                                                                                uid
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                            mobile_x_position
                                                            subline {
                                                                text
                                                            }
                                                            layout
                                                            icon {
                                                                url
                                                            }
                                                            headline {
                                                                text
                                                            }
                                                            fade_out_at
                                                            fade_in_at
                                                            desktop_y_position
                                                            desktop_x_position
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                ... on PrismicPageBodyCountingNumbers {
                                    id
                                    primary {
                                        headline {
                                            text
                                        }
                                        layout
                                    }
                                    slice_type
                                    items {
                                        text {
                                            text
                                        }
                                        suffix {
                                            text
                                        }
                                        prefix {
                                            text
                                        }
                                        number
                                        icon {
                                            url
                                        }
                                    }
                                }
                                ... on PrismicPageBodyTextTeaser {
                                    id
                                    primary {
                                        text {
                                            html
                                        }
                                        link {
                                            uid
                                            type
                                            url
                                            lang
                                            document {
                                                ... on PrismicPage {
                                                    data {
                                                        parent_page {
                                                            uid
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        layout
                                        button_label {
                                            text
                                        }
                                        headline {
                                            text
                                        }
                                    }
                                    slice_type
                                }
                                ... on PrismicPageBodyLargeImageTeaser {
                                    id
                                    primary {
                                        text {
                                            html
                                        }
                                        link {
                                            uid
                                            type
                                            lang
                                            url
                                            document {
                                                ... on PrismicPage {
                                                    data {
                                                        parent_page {
                                                            uid
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        layout
                                        image {
                                            url
                                        }
                                        icon {
                                            url
                                        }
                                        headline {
                                            text
                                        }
                                        button_label {
                                            text
                                        }
                                    }
                                    slice_type
                                }
                                ... on PrismicPageBodyTextBanner {
                                    id
                                    primary {
                                        subline {
                                            text
                                        }
                                        layout
                                        headline {
                                            text
                                        }
                                    }
                                    items {
                                        text {
                                            text
                                        }
                                        icon {
                                            url
                                        }
                                        headline {
                                            text
                                        }
                                    }
                                }
                                ... on PrismicPageBodyImageTeaserGroup {
                                    id
                                    slice_type
                                    items {
                                        image {
                                            url
                                        }
                                        link_label {
                                            text
                                        }
                                        link {
                                            type
                                            uid
                                            id
                                            lang
                                            url
                                            document {
                                                ... on PrismicPage {
                                                    data {
                                                        parent_page {
                                                            uid
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        subline {
                                            text
                                        }
                                        text {
                                            html
                                        }
                                    }
                                    primary {
                                        headline {
                                            text
                                        }
                                        layout
                                        text {
                                            html
                                        }
                                    }
                                }
                                ... on PrismicPageBodyQuote {
                                    id
                                    slice_type
                                    primary {
                                        text {
                                            text
                                        }
                                        subline {
                                            text
                                        }
                                        image {
                                            url
                                            thumbnails {
                                                mobile {
                                                    url
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `)
    const page = data.page.edges.find(
        item => item.node.lang === activeDocMeta.lang
    ).node.data

    const slices = page.body

    return (
        <DefaultLayout activeDocMeta={activeDocMeta}>
            <SEO
                title={page.title.text}
                description={page.meta_description.text}
                lang={activeDocMeta.lang}
            />
            <SliceZoneRenderer activeDocMeta={activeDocMeta} slices={slices} />
        </DefaultLayout>
    )
}
